.cards-grid-container {
  width: 100%;
  flex: 1 1;
  overflow-y: auto;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(auto-fill, 200px);
  grid-gap: 20px;
  grid-auto-rows: minmax(-webkit-min-content, -webkit-max-content);
  grid-auto-rows: minmax(min-content, max-content);
}

.single-card-container {
  padding: 24px;
  width: 195px;
  height: 391px;
  background: white;
  border: 1px solid #dfe2e8;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.single-card-text-title:hover{
	text-decoration: underline;
}

.single-card-thumbnail {
  border-radius: 8px;
  height: 197px;
  width: 147px;
}


.cards-wrapper .k-pager-wrap{
	background: transparent;
	border: none;
}

.cards-wrapper a.k-link.k-pager-nav {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}

.cards-wrapper .k-pager-nav.k-link:focus {
  background: unset !important;
}

.cards-wrapper .k-pager-wrap.k-pager.k-widget.k-grid-pager {
  background: white !important;
  box-shadow: none !important;
  outline: none !important;
}

.cards-wrapper .k-pager-nav.k-link,.cards-wrapper .k-pager-refresh {
  color: #293c66 !important;
  opacity: 0.5 !important;
}

.cards-wrapper .k-pager-numbers-wrap a.k-link {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  color: #293c66 !important;
}

.cards-wrapper .k-pager-numbers .k-link.k-state-selected {
  color: #293c66 !important;
  background: #e9ebef !important;
}

.cards-wrapper .k-pager-wrap.k-pager.k-widget.k-grid-pager .k-icon {
  color: #293c66 !important;
  opacity: 0.5 !important;
}

.cards-wrapper .k-pager-wrap.k-pager.k-widget.k-grid-pager a:hover {
  background-color: #dfe2e8 !important;
}

.cards-wrapper .k-pager-wrap.k-pager.k-widget.k-grid-pager a:hover .k-icon {
  opacity: 1 !important;
}

.cards-wrapper .k-link,.cards-wrapper .k-pager-refresh{
	background: transparent;
}

.cards-wrapper span.k-pager-sizes.k-label,.cards-wrapper .k-pager-info.k-label {
  color: #afb5c5 !important;
}

.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header
  span.k-input,
.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header
  span.k-select
  span.k-icon.k-i-arrow-s {
  color: #afb5c5;
}

.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-input,
.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-select,
.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-select
  span.k-icon.k-i-arrow-s {
  color: #293c66 !important;
  background-color: #dfe2e8 !important;
}

.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-input,
.cards-wrapper
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-select
  span.k-icon.k-i-arrow-s {
  color: #293c66 !important;
  background-color: #dfe2e8 !important;
}

span.k-pager-sizes.k-label {
  font-size: 14px;
}

.k-pager-info.k-label {
  font-size: 14px;
}

.cards-wrapper .k-pager-wrap .k-dropdown .k-dropdown-wrap:hover {
  background: white;
}

.cards-wrapper .k-pager-numbers .k-link:hover {
  background-color: #dfe2e8 !important;
}
