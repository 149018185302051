.main-grid .k-grid{
	border-color: #DFE2E8 !important;
	border-radius: 8px !important;
}

.main-grid .k-grid-header{
	background: #e9ebef !important;
}

.main-grid thead .k-header{
	border: none !important;
	color: #adb4c4 !important;
	font-weight: 500;
}

.main-grid input.k-textbox:focus{
box-shadow: none;
outline: none;
}
.canvas-container-thumbnail{
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.main-grid tr.k-filter-row th{
	border:  none !important;
}

.main-grid span.k-icon.k-i-filter.k-icon{
	color: #293C66 !important;
}

.main-grid td{
	color: #293C66 !important;
	border: none !important;
}

.main-grid tr.k-master-row.k-alt{
	background: #F4F5F7;
}

.main-grid a.k-link.k-pager-nav{
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	border-radius: 50% !important;
}

.main-grid .k-pager-nav.k-link:focus{
	background: unset !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager{
    background: white !important;
	box-shadow: none !important;
	outline: none !important;
}

.main-grid .k-pager-numbers-wrap a.k-link{
	border: none !important;
	outline: none !important;
	box-shadow: none !important;
	border-radius: 50% !important;
	color: #293C66 !important;
}

.main-grid .k-pager-numbers .k-link.k-state-selected{
	color: #293C66 !important;
	background: #e9ebef !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager .k-icon{
	color: #293C66 !important;
	opacity: 0.5 !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager a:hover{
	background-color: #dfe2e8 !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager a:hover .k-icon{
	opacity: 1 !important;
}

.main-grid span.k-pager-sizes.k-label{
	color: #afb5c5 !important;
}

.main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header span.k-input, .main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header span.k-select span.k-icon.k-i-arrow-s{
	color: #afb5c5 ;
}

.main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header:hover span.k-input, .main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header:hover span.k-select, .main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header:hover span.k-select span.k-icon.k-i-arrow-s{
	color: #293C66 !important;
	background-color: #dfe2e8 !important;
}

.main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header:hover span.k-input, .main-grid span.k-pager-sizes.k-label span.k-widget.k-dropdown.k-header:hover span.k-select span.k-icon.k-i-arrow-s{
	color: #293C66 !important;
	background-color: #dfe2e8 !important;
}

.k-list .k-item.k-state-selected, .k-list-optionlabel.k-state-selected{
	background: #e9ebef !important;
	color: #293C66 !important;
}

.main-grid .k-pager-info.k-label{
	color: #afb5c5 !important;
}

.main-grid span.k-icon.k-i-refresh{
	color: #afb5c5 !important;
}

.main-grid .k-filtercell-operator{
	/* display: none; */
}

.main-grid .k-pager-wrap .k-dropdown .k-dropdown-wrap:hover{
	background:white;
}

.ar-file-input-remove-button{
	position: absolute;
	border-radius: 50%;
	top: 0;
	width: 32px;
	height: 32px;
	right: 3px;
	background: #293c6680 0% 0% no-repeat padding-box;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Disablehover:hover{
	color: #00abcd;
	
}

.LeafletForm{
	background: white;
	border-radius: 8px;
}

.Disablehover{
	text-decoration: none;
}

.main-grid .k-filter-row th{
	padding: .375rem .75rem;
}

.k-filtercell .k-filtercell-wrapper>.k-textbox{
	height: 32px !important;
}

.main-grid .k-grid tbody tr:hover, .k-grid tbody tr.k-state-hover{
	background-color: #dfe2e8 !important;
}

.main-grid .k-pager-numbers .k-link:hover{
	background-color: #dfe2e8 !important;
}

.k-list .k-item:hover{
	background-color: #dfe2e8 !important;
}

.main-grid .k-dropdown .k-dropdown-wrap.k-state-focused, .main-grid .k-dropdowntree .k-dropdown-wrap.k-state-focused{
	box-shadow: none !important;
}