.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
}

@media (min-width: 1180px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
