.graphContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  align-items: center;
  position: sticky;
  top: 1rem;
}

.graph {
  overflow: hidden;
  height: 300px;
}
.leafleatGraph {
  overflow: hidden;
}

.leafleatContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.leafleatItem {
  background: #e9ebef;
}

.leafleatDates {
  color: rgba(41, 60, 102, 0.5);
}

.leafleatThumb {
  height: 76px;
  width: 52px;
  object-fit: cover;
}

.leafleatLink {
  color: #293c66;
  transition: 0.3s;
  text-decoration: none;
}
.leafleatLink:hover {
  color: rgba(41, 60, 102, 0.5);
}

.articleInput {
  max-width: 460px;
}

.articleInput :global(.k-dropdown-wrap) {
  background: white !important;
}

.stickyTitle {
  position: sticky;
  top: 0;
  background: white;
}
