.app-login-container {
}

.app-login-ilustration {
  width: 100%;
  height: 100%;
  background-image: url("../Assets/Img/LoginIllustration.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
