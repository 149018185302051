.app-button {
  font-size: 14px;
  letter-spacing: 0.7px;
  font-weight: 500;
  border: none;
  outline: none !important;
  height: 48px;
  transition: 0.3s;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  text-transform: uppercase;
}

.k-checkbox:checked {
  background-color: #00abcd !important;
  border-color: #00abcd !important;
}

.k-button {
  outline: none;
  border: none !important;
  box-shadow: none i !important;
}

.size-sm {
  width: 29px !important;
  height: 29px !important;
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.app-button.primary,
.k-button.k-primary {
  color: #ffffff !important;
  background: #00abcd !important;
}
.app-button.primary:hover,
.k-button.k-primary:hover {
  color: #ffffff !important;
  background: #00ccf4 !important;
}
.app-button.primary:active,
.k-button.k-primary:active {
  color: #ffffff !important;
  background: #008aa6 !important;
}

.app-button.primary:disabled,
.k-button.k-primary:disabled {
  color: #00abcd !important;
  background: rgba(0, 171, 205, 0.1) !important;
}

.app-button.warning {
  color: #d26635;
  background: rgba(210, 102, 53, 0.1);
}

.app-button.warning:hover {
  color: #ffffff;
  background: rgba(210, 102, 53, 1);
}

.app-button.informative {
  color: #293c6680;
  background: transparent;
  border: 1px solid #e9ebef;
}

.app-button.informative:hover {
  color: #293c66;
  background: #e9ebef;
  border: 1px solid #e9ebef;
}

.app-button.delete {
  color: #d26635;
  background: rgba(210, 103, 53, 0.1);
}
.app-button.primary:hover {
  color: white;
  background: #d26635;
}
.app-button.primary:active {
  color: white;
  background: #d26635;
}

/* .app-button.primary:disabled {
  color: #00abcd !important;
  background: rgba(0, 171, 205, 0.1) !important;
} */
