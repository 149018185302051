.ar-modal {
  transition: 0.3s;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ar-modal.active {
  background: #293c6640;
}

.ar-modal-content {
  transform: scale(0.85);
  transform-origin: top;
  transition: 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 8px 16px #293c6629;
  max-height: 90%;
  border-radius: 7px;
  background: white;
  overflow: auto;
}

.ar-modal.active .ar-modal-content {
  transform: scale(1);
  opacity: 1;
  visibility: visible;
}


.ar-dropdown-modal .k-dropdown .k-dropdown-wrap,.ar-dropdown-modal .k-dropdowntree .k-dropdown-wrap{
	background: #f4f5f7;
}

.ar-dropdown-modal .k-dropdown .k-dropdown-wrap:hover,.ar-dropdown-modal .k-dropdowntree .k-dropdown-wrap:hover{
	background: #f4f5f7;
}

.ar-dropdown-modal .k-dropdown .k-dropdown-wrap.k-state-focused,.ar-dropdown-modal .k-dropdowntree .k-dropdown-wrap.k-state-focused{
	background: #f4f5f7;
	border-color: #00abcd !important;
}

