.app-checkbox {
  display: flex;
	align-items: center;
  position: relative;
  padding-left: 28px;

  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.app-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 20px;
  width: 20px;
  /* background-color: #eee; */
  border: solid 1px #d3d7df;
  border-radius: 4px;
  transition: 0.3s;
}

/* On mouse-over, add a grey background color */
.app-checkbox:hover input ~ .checkmark {
  background-color: #e5f7fa;
}

/* When the checkbox is checked, add a blue background */
.app-checkbox input:checked ~ .checkmark {
  background-color: #00abcd;
  border-color: #00abcd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.app-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.app-checkbox .checkmark:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 13px;
  border: solid white;
  border-width: 0px 3px 3px 0;
  transform: rotate(45deg);
}
