.k-autocomplete {
  /* height: 44px; */
  /* background: red; */
  border-radius: 8px;
  background-color: #f4f5f7 !important;
  border: solid 2px transparent !important;
  overflow: hidden;
}

.k-autocomplete.k-state-focused {
  box-shadow: none !important;
  border-color: #00abcd !important;
}

.k-autocomplete .k-input {
  height: 46px;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  /* background-color: #f4f5f7 !important; */
}

.k-autocomplete .k-clear-value {
  top: auto !important;
}

.k-animation-container > .k-popup {
  box-shadow: 0 0px 16px #293c6629;
  border: none;

}

.k-list .k-item {
  color: #293c66;
  box-shadow: none !important;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.k-list .k-item.k-state-focused,
.k-list .k-item:hover {
  background: rgba(0, 171, 205, 0.1);
  color: #00abcd;
}
