.app-input {
  height: 50px;
  background: #f4f5f7;
  border: 2px solid transparent !important;
  outline: none;
  transition: 0.3s;
}

.euro-input-container > input {
	padding-right: 28px !important;
}

.euro-input-container::after{
	position: absolute;
	right: 0;
	margin-right: 12px;
	line-height: 48px;
	content: "€";
}

.app-input.secondary {
  background: white;
}

input:required:invalid:not(:placeholder-shown) {
  border-color: crimson !important;
}

.k-numeric-wrap{
	border: none !important;
	border-radius: 8px !important;
}

.input-file-icon {
  color: #293c66;
  opacity: 0.5;
}

.app-input:focus-within {
	border-color: #00abcd !important;
  }
.app-input:focus {
  border-color: #00abcd !important;
}
