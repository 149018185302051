.main-grid .k-grid {
  border-color: #dfe2e8 !important;
  border-radius: 8px !important;
}

.main-grid .k-grid-header {
  background: #e9ebef !important;
}

.main-grid thead .k-header {
  border: none !important;
  color: #adb4c4 !important;
  font-weight: 500;
}

.main-grid input.k-textbox:focus {
  box-shadow: none;
  outline: none;
}

.hide-grid-title a {
  visibility: hidden;
}

.k-drag-clue {
  border-color: transparent !important;
  color: #adb4c4 !important;
  background-color: #e9ebef !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
									supported by Chrome, Edge, Opera and Firefox */
}

.main-grid tr.k-filter-row th {
  border: none !important;
}

.main-grid span.k-icon.k-i-filter.k-icon {
  color: #293c66 !important;
}

.main-grid td {
  color: #293c66 !important;
  border: none !important;
}

.main-grid tr.k-master-row.k-alt {
  background: #f4f5f7;
}

.main-grid a.k-link.k-pager-nav {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
}

.main-grid .k-pager-nav.k-link:focus {
  background: unset !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager {
  background: white !important;
  box-shadow: none !important;
  outline: none !important;
}

.main-grid .k-pager-numbers-wrap a.k-link {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  color: #293c66 !important;
}

.main-grid .k-pager-numbers .k-link.k-state-selected {
  color: #293c66 !important;
  background: #e9ebef !important;
}

.main-grid .k-pager-nav.k-link,
.main-grid .k-pager-refresh {
  background: transparent;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager .k-icon {
  color: #293c66 !important;
  opacity: 0.5 !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager a:hover {
  background-color: #dfe2e8 !important;
}

.main-grid .k-pager-wrap.k-pager.k-widget.k-grid-pager a:hover .k-icon {
  opacity: 1 !important;
}

.main-grid span.k-pager-sizes.k-label {
  color: #afb5c5 !important;
}

.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header
  span.k-input,
.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header
  span.k-select
  span.k-icon.k-i-arrow-s {
  color: #afb5c5;
}

.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-input,
.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-select,
.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-select
  span.k-icon.k-i-arrow-s {
  color: #293c66 !important;
  background-color: #dfe2e8 !important;
}
.k-column-list-item {
  text-align: left;
}
span.k-widget.k-dropdown.k-header {
  user-select: none;
}
.k-popup {
  padding: 0 !important;
}

.k-grid-columnmenu-popup > div > br {
  display: none;
}

/* .k-list .k-item.k-state-focused, .k-list .k-item:hover{
	color: #293C66 !important;
	background-color: #dfe2e8 !important;
} */

.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-input,
.main-grid
  span.k-pager-sizes.k-label
  span.k-widget.k-dropdown.k-header:hover
  span.k-select
  span.k-icon.k-i-arrow-s {
  color: #293c66 !important;
  background-color: #dfe2e8 !important;
}

.k-list .k-item.k-state-selected,
.k-list-optionlabel.k-state-selected {
  background: #e9ebef !important;
  color: #293c66 !important;
}

.main-grid .k-pager-info.k-label {
  color: #afb5c5 !important;
}

.main-grid span.k-icon.k-i-refresh {
  color: #afb5c5 !important;
}

.main-grid .k-filtercell-operator {
  /* display: none; */
}

.main-grid span.k-select {
  width: 30px !important;
}

.main-grid .k-filtercell-wrapper {
  align-items: center;
}

.main-grid button.k-button.k-button-icon {
  height: 32px !important;
  width: 32px !important;
  margin-left: 5px;
}

.main-grid .k-textbox {
  font-size: 14px !important;
}

.main-grid .k-icon {
  font-size: 18px;
}

span.k-pager-sizes.k-label {
  font-size: 14px;
}

.k-pager-info.k-label {
  font-size: 14px;
}

.main-grid td {
  font-size: 14px;
}

.k-clear-button-hidden {
  display: none;
}

.k-list-scroller {
  font-size: 14px !important;
}

.main-grid .k-datepicker .k-input {
  height: 30px !important;
  font-size: 14px !important;
}

.main-grid span.k-widget.k-dateinput {
  height: 30px !important;
}

.main-grid .k-input {
  font-size: 14px !important;
}

.main-grid .k-pager-wrap .k-dropdown .k-dropdown-wrap:hover {
  background: white;
}

.main-grid .k-filter-row th {
  padding: 0.375rem 0.75rem;
}

.k-filtercell .k-filtercell-wrapper > .k-textbox {
  height: 32px !important;
}

.main-grid .k-grid tbody tr:hover,
.k-grid tbody tr.k-state-hover {
  background-color: #dfe2e8 !important;
}

.main-grid .k-pager-numbers .k-link:hover {
  background-color: #dfe2e8 !important;
}

.k-list .k-item:hover {
  background-color: #dfe2e8 !important;
}

.main-grid .k-dropdown .k-dropdown-wrap.k-state-focused,
.main-grid .k-dropdowntree .k-dropdown-wrap.k-state-focused {
  box-shadow: none !important;
}

.main-grid .grid-button {
  color: #293c66;
  opacity: 0.5;
  cursor: pointer;
}

.main-grid .k-grid tbody tr:hover .grid-button,
.main-grid .k-grid tbody tr.k-state-hover .grid-button {
  opacity: 1;
}

.main-grid .k-grid th,
.main-grid .k-grid td {
  height: 48px;
}
