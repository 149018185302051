.filterList {
  width: 292px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dfe2e8;
}

.filterList.closed {
  width: min-content !important;
  height: min-content !important;
  transition: none !important;
}

.filterListHeader {
  background: #e9ebef;
  height: 48px;
  color: rgba(41, 60, 102, 0.5);
  /* border-bottom: 1px solid #dfe2e8; */
}

.filter {
  color: #293c66;
  border-bottom: 1px solid #dfe2e8;
  transition: 0.3s;
}

/* .filter.selected {
  background: #f4f5f7;
} */
/* label.selected {
  font-weight: 500;
} */

.filterButton {
  color: #00abcd;
  cursor: pointer;
}

.searchInput {
  width: 100%;
  font-size: 14px;
  color: #293c66;
  border-radius: 4px;
  height: 32px;
  border: 1px solid #dfe2e8;
  outline: none;
}

.searchInput:focus {
  border-color: #00abcd;
}

.filterItemContainer {
  margin: -0.25rem;
}

.filterItemBadge {
  background: #e9ebef88;
  color: rgba(41, 60, 102, 1);
  font-size: 12px;
  border-radius: 4px;
  margin: 0.25rem;
  max-width: calc(100% - 0.5rem);
  cursor: pointer;
  user-select: none;
}

.filterCountBadge {
  font-size: 14px;
  font-weight: 300;
  background: #00abcd;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s;
}

.filterNewButton {
  width: 32px;
  height: 32px;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: -0.5rem;
}

.filterCountBadge:hover {
  background: #00ccf4;
}

.filterApplyButton {
  height: 30px;
  width: 100%;
  padding: 0.25rem 0.5rem !important;
  font-size: 12px;
}

.filterToggleButton {
  width: 32px;
  height: 32px;
  border: 1px solid #00abcd;
  transition: 0.3s;
  color: #00abcd;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

/* .filterToggleButton:hover{

} */

.filterToggleButton.selected {
  background: #00abcd;
  color: white;
}

.active {
  color: #ffffff;
  background: #00abcd;
}
.inactive {
  color: #00abcd;
  /* background: #ffffff; */
}

.active:hover {
  color: #ffffff;
  background: #00ccf4;
}
.inactive:hover {
  color: #ffffff;
  background: #00ccf4;
}
.active:active {
  color: #ffffff;
  background: #008aa6;
}
.inactive:active {
  color: #ffffff;
  background: #008aa6;
}

.buttonContainer {
  border: 1px solid #00abcd;
  border-radius: 8px;
  overflow: hidden;
}
.buttonContainer > button {
  border-radius: 0 !important;
  width: 48px;
  height: 48px;
  padding: 0;
}

.FirstButton {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.LastButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.filterListOptions {
  position: relative;
  cursor: pointer;
}

.filterListOptionsPopup {
  box-shadow: 0px 0px 8px #00000029;
  background: white;
  position: absolute;
  right: -16px;
  top: 40px;
  width: 194px;
  z-index: 1;
  border-radius: 8px;
}

.filterListOption {
  user-select: none;
  color: rgba(41, 60, 102, 0.5);
  transition: 0.3s;
  cursor: pointer;
}
.filterListOption:hover {
  color: rgba(41, 60, 102);
  background: #f4f5f7;
}

.filterListOptionPrimary {
  color: #00abcd !important;
}

.filterListHeaderChevron {
  cursor: pointer;
  user-select: none;
}

.filterListChangerItem {
  transition: 0.3s;
  cursor: pointer;
  user-select: none;
}
.filterListChangerItem:hover {
  background: #e9ebef;
}

.filterListChangerSelectedItem {
  background: #e9ebef;
  border-radius: 4px;
  color: rgba(41, 60, 102, 0.5);
  cursor: pointer;
  user-select: none;
}
