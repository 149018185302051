html,
body,
#root {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  color: #293c66;
}

.bg-primary {
  background: #ffffff !important;
}

.bg-secondary {
  background: #f4f5f7 !important;
}

.text-primary {
  color: #293c66;
}

.fs-24 {
  font-size: 24px;
}

.text-secondary {
  color: rgba(41, 60, 102, 0.5) !important;
}

.br-8 {
  border-radius: 8px;
}

.text-danger {
  color: #d26635 !important;
}

.color-primary {
  color: #00abcd;
}

.of-y-auto {
  overflow-y: auto;
}

.disable-all-overflow,
.disable-all-overflow * {
  overflow: hidden !important;
  box-shadow: none !important;
}

.of-auto {
  overflow: auto;
}

.fs-40 {
  font-size: 40px;
}

.fs-20 {
  font-size: 20px;
}

.fw-light {
  font-weight: 300;
}

.fw-medium {
  font-weight: 500;
}

.fw-bold {
  font-weight: 700;
}

.rounded {
  border-radius: 8px !important;
}

.flex-1 {
  flex: 1 1;
}

.cursor-pointer {
  cursor: pointer;
}

.app-popup-link {
  /* font-size: 20px; */
  color: #293c66;
  transition: 0.3s;
  display: block;
  text-decoration: none !important;
}

.app-popup-link.lg {
  font-size: 20px;
}

.app-popup-link:hover {
  color: #00abcd !important;
  background: rgba(0, 171, 205, 0.1) !important;
}

.app-splitter {
  margin: 0 -0.5rem;
}

.border {
  border: 1px solid #dfe2e8 !important;
}

.link-primary {
  text-decoration: none;
  letter-spacing: 0.7px;
  color: #00abcd !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
}

.k-list-filter {
  display: block;
  position: relative;
  padding: 1rem;
  min-height: calc(2.75rem + 1.5em);
  box-sizing: border-box;
  flex: 1 0 auto;
}

.k-list-filter > .k-textbox {
  width: 100% !important;
  box-sizing: border-box;
  padding-left: 0.75rem;
  padding-right: calc(16px + 1rem);
}

.text-blacker {
  color: #464646 !important;
}

.k-list-filter > .k-icon {
  position: absolute;
  right: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.tabsArticles .ar-dropdown-modal .k-input {
  background: white;
}
