



.percentage-loader circle{
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.percentage-loader .percentage-text{
	color: #00abcd !important;
	font-size: 22px;
	font-weight: 500;
}

.percentage-loader .circle-bg {
  stroke: #e9ebef;
}

.percentage-loader .circle-color {
  stroke: #00abcd;
  stroke-dasharray: 380;
  stroke-dashoffset: 380;
}


.percentage-loader .loading {
  margin: 0 auto;
  font-size: 124px;
  width: 1em;
  height: 1em;
}

.percentage-loader .loading svg {
  display: block;
  width: 100%;
  height: 100%;
}