.navbar {
  width: 292px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #dfe2e8;
}

.navbarHeader {
  background: #f4f5f7;
  color: rgba(41, 60, 102, 0.5);
}
