.rolesCheckbox {
  background: #f4f5f7;
}

.container {
  background: white;
  border-radius: 8px;
}

.rolesContainer {
  width: 438px;
}

.configItem {
	height: 280px;
	width: 260px;
	background: #f4f5f7;
	border: 1px solid #dfe2e8;
	border-bottom: 1px solid #dfe2e8;
}

.configItemTitle {
  background: #dfe2e8;
  color: rgba(41, 60, 102, 0.5);
}

.configInput {
  width: 100%;
  font-size: 14px;
  color: #293c66;
  border-radius: 4px;
  height: 32px;
  border: 1px solid #dfe2e8;
  outline: none;
}

.configGrid {
	display: inline-grid;
	grid-template-columns: repeat(auto-fit, minmax(260px, auto));
	grid-gap: 16px;
	overflow: auto;
	height: 100%;
	width: 100%;
}

.permissionIlust {
  width: 371px;
  height: 224px;
  background-image: url("../../Assets/Img/LoginIllustration.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
