.dashboard {
  /* max-width: 1200px; */
  width: 100%;
}

.containerLogo {
  width: 371px;
  height: 224px;
  background-image: url("../Assets/Img/LoginIllustration.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.leaflets {
  display: flex;
  margin: -0.75rem;
}

.leafletContainer {
  flex: 1 1 47%;
  margin: 0.75rem;
}

.promotion {
  color: inherit !important;
  text-decoration: none;
  transition: 0.3s;
}
.promotion:hover {
  background: #dfe2e8;
}

.promotionImage {
  width: 104px;
  height: 104px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.promotionPriceBefore {
  text-decoration: line-through;
}

.addButton {
  width: 48px;
  width: 48px;
  padding: 0;
}
