.app-header {
  height: 80px;
  border-bottom: solid 1px #dfe2e8;
}

.app-header-side {
  width: 195px;
}

.app-header-logo {
  width: auto;
  height: 48px;
}

.app-header-group-popup {
  border-top: solid 1px #dfe2e8;
  display: none;
  position: absolute;
  top: 79px;
  left: 0;
  z-index: 10000;
  width: 235px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 8px 16px #293c6629;
  overflow: hidden;
}
.app-header-group:hover .app-header-group-popup {
  display: block;
}

.app-header-link {
  height: 100%;
  font-size: 20px;
  color: rgba(41, 60, 102, 0.5);
  text-decoration: none;
  transition: all 200ms ease;
  position: relative;
  display: flex;
  align-items: center;
}

.app-header-link::after {
  left: 0;
  bottom: -2px;
  position: absolute;
  content: "";
  height: 3px;
  width: 100%;
  transition: all 200ms ease;
  background: transparent;
}

.app-header-group:hover .app-header-link.group::after {
  content: none;
}

.app-header-group:hover .app-header-link,
.app-header-link:hover,
.app-header-link.selected {
  /* font-weight: 500; */
  color: #293c66;
}

.app-header-link:hover::after,
.app-header-link.selected::after {
  background: #00abcd;
}

.app-header-profile {
  width: 48px;
  height: 48px;
  background: rgba(0, 171, 205, 0.1);
  border-radius: 50%;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00abcd;
  position: relative;
}

.app-header-profile.interectable {
  margin-left: auto;
  cursor: pointer;
}

.app-header-profile.interectable:hover,
.app-header-profile.interectable.active {
  background: #00abcd;
  color: white;
}

.app-header-profile-popup {
  position: absolute;
  z-index: 10000000;
  background: #ffffff;
  top: 64px;
  right: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 400px;
  box-shadow: 0 8px 16px #293c6629;
}

.app-header-signout {
  color: rgba(41, 60, 102, 0.5);
  transition: 0.3s;
}
.app-header-signout:hover {
  color: #d26635;
  cursor: pointer;
  display: inline-block;
}
