.ar-dropdown-modal .k-input {
  height: 46px !important;
  background: #f4f5f7;
  border: 2px solid transparent;
  outline: none;
  transition: 0.3s;
}

.ar-dropdown-modal .k-dropdown-wrap.k-state-default {
  background: #f4f5f7;
  border-width: 2px;
  border-color: transparent;
  /* border: 2px solid transparent !important; */
  outline: none !important;
}

.k-multiselect .k-multiselect-wrap .k-button,
.k-dropdowntree .k-multiselect-wrap .k-button {
  margin: calc(0.25rem + 3px) 0 0 calc(0.25rem + 3px) !important;
}

.ar-dropdown-modal
  .k-widget.k-multiselect.k-header.k-state-focused
  .k-multiselect-wrap.k-floatwrap:focus-within {
  border-color: #00abcd !important;
}

.ar-dropdown-modal
  .k-widget.k-multiselect.k-header
  .k-multiselect-wrap.k-floatwrap {
  background-color: #f4f5f7 !important;
  border-radius: 8px !important;
  border: 2px solid transparent;
}

span.k-numeric-wrap {
  background-color: #f4f5f7 !important;
}

.ar-dropdown-modal .k-dropdown .k-dropdown-wrap.k-state-focused,
.ar-dropdown-modal .k-dropdowntree .k-dropdown-wrap.k-state-focused,
.ar-dropdown-modal
  .k-widget.k-multiselect.k-header.k-state-focused
  .k-multiselect-wrap.k-floatwrap {
  box-shadow: none;
}
