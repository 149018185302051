.tabsHeader {
  display: inline-flex;
}

.tabsHeaderItem {
  padding: 12px 10px;
  position: relative;
  font-size: 18px;
  cursor: pointer;
  user-select: none;
}

.tabsHeaderItem.selected {
  font-weight: 700;
}

.tabsHeaderItem.selected::after {
  content: "";
  position: absolute;
  bottom: 2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #00abcd;
}
