.container {
  border: 1px solid #00abcd;
  border-radius: 8px;
  overflow: hidden;
}

.container > button {
  border-radius: 0 !important;
  height: 48px;
  width: 48px;
  padding: 0;
}

.FirstButton {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.LastButton {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.active {
  color: #ffffff;
  background: #00abcd;
}
.inactive {
  color: #00abcd;
  /* background: #ffffff; */
}

.active:hover {
  color: #ffffff;
  background: #00ccf4;
}
.inactive:hover {
  color: #ffffff;
  background: #00ccf4;
}
.active:active {
  color: #ffffff;
  background: #008aa6;
}
.inactive:active {
  color: #ffffff;
  background: #008aa6;
}
