.k-datepicker .k-input {
  height: 44px;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.k-datepicker .k-picker-wrap {
  box-shadow: none !important;
  border-radius: 8px;
  border: solid 2px #f4f5f7 !important;
  /* border-color: transparent !important; */
  overflow: hidden;
  border-width: 2px !important;
}

.k-datepicker .k-picker-wrap.k-state-focused {
  border-color: #00abcd !important;
}

.k-datepicker .k-dateinput-wrap {
  background: #f4f5f7;
}
.k-datepicker .k-picker-wrap {
  background-color: #f4f5f7;
}

.k-datepicker .k-picker-wrap .k-select {
  border: none;
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
  color: rgba(41, 60, 102, 0.5) !important;
  background-color: #f4f5f7;
}

.k-datepicker .k-picker-wrap:hover .k-select {
  background-color: #f4f5f7;
}

.filterDatePicker {
  width: 100% !important;
}

.filterDatePicker .k-dateinput-wrap {
  background: white !important;
}

.filterDatePicker .k-picker-wrap .k-select {
  background-color: white !important;
}

.filterDatePicker .k-picker-wrap {
  border-width: 1px !important;
}
